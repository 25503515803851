import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import ManageWebhooksModalComponent from '../ManageWebhooksModalComponent/ManageWebhooksModalComponent';
import { deleteWebhook, pingWebhook } from '../../../apis/webhooks.ts';
import { useToast } from '../../../context/ToastContext';

async function pingWebhookHandler(id, toast) {
  const response = await pingWebhook(id);
  if (response.success) {
    toast.current.show({
      severity: 'success', summary: 'Success', detail: response.message, life: 3000,
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } else {
    toast.current.show({
      severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? `There was a problem trying to ping the webhook: ${response.response.data.message}` : 'An unexpected error occurred pinging the webhook', life: 3000,
    });
  }
}

async function acceptDeleteWebhook(webhook, toast) {
  const response = await deleteWebhook(webhook.id);
  if (response.success) {
    toast.current.show({
      severity: 'success', summary: 'Success', detail: response.message, life: 3000,
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } else {
    toast.current.show({
      severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? `There was a problem trying to remove the webhook: ${response.response.data.message}` : 'An unexpected error occurred deleting the webhook', life: 3000,
    });
  }
}

const deleteWebhookConfirmation = (webhook, toast) => {
  confirmDialog({
    message: 'Do you want to delete this webhook?',
    header: `Delete ${webhook.name} webhook`,
    icon: 'pi pi-info-circle',
    defaultFocus: 'reject',
    acceptClassName: 'p-button-danger',
    accept: () => acceptDeleteWebhook(webhook, toast),
  });
};

function WebhooksListComponent({ webhooks }) {
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState();
  const [selectedWebhooks, setSelectedWebhooks] = useState([]);
  const settingsRef = useRef(null);
  const toast = useToast();

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      ],
    },
    url: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      ],
    },
  });

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => (
    <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
      <h4 className="m-0">Webhooks</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
      </span>
    </div>
  );
  const header = renderHeader();
  const actions = () => (
    [
      {
        label: 'Ping',
        icon: 'pi pi-phone',
        command: () => { pingWebhookHandler(selectedWebhooks.id, toast); },
      },
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => { setMode('edit'); setVisible(true); },
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => { deleteWebhookConfirmation(selectedWebhooks, toast); },
      },
    ]);

  const actionBodyTemplate = (value) => (
    <Button type="button" icon="pi pi-cog" className="justify-content-center align-items-center h-3rem w-3rem border-circle" style={{ marginLeft: '-0.5rem' }} onClick={(e) => { settingsRef.current.toggle(e); setSelectedWebhooks(value); }}>
      <TieredMenu model={actions()} popup ref={settingsRef} style={{ width: 'auto' }} />
    </Button>
  );

  return (
    webhooks?.length > 0 && (
      <>
        <ConfirmDialog />
        <ManageWebhooksModalComponent
          visible={visible}
          setVisible={setVisible}
          webhookId={selectedWebhooks.id}
          mode={mode}
        />
        <DataTable
          size="small"
          value={webhooks}
          paginator
          header={header}
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          selection={selectedWebhooks}
          onSelectionChange={(e) => setSelectedWebhooks(e.value)}
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={['name', 'url', 'is_active']}
          emptyMessage="No webhooks found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} webhooks"
        >
          <Column field="name" header="Name" sortable filter filterPlaceholder="Search by name" style={{ minWidth: '8rem' }} />
          <Column field="url" header="URL" sortable filter filterPlaceholder="Search by URL" style={{ minWidth: '14rem' }} />
          <Column field="is_active" header="Active" sortable style={{ minWidth: '2rem' }} />
          <Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={(e) => actionBodyTemplate(e)} />
        </DataTable>
      </>
    )
  );
}

WebhooksListComponent.propTypes = {
  webhooks: PropTypes.array,
};

WebhooksListComponent.defaultProps = {
  webhooks: [],
};

export default WebhooksListComponent;
