import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';

function ImportGroupModalConfirmation({
  visible, onHide, onConfirm, message, header,
}) {
  const footer = (
    <div>
      <Button label="No" icon="pi pi-times" onClick={onHide} className="p-button-text" />
      <Button label="Yes" icon="pi pi-check" onClick={onConfirm} className="p-button-danger" />
    </div>
  );

  const headerContent = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <i className="pi pi-exclamation-triangle" style={{ fontSize: '2em', color: 'orange', marginRight: '0.5rem' }} />
      <span>{header}</span>
    </div>
  );

  return (
    <Dialog
      visible={visible}
      header={headerContent}
      modal
      footer={footer}
      onHide={onHide}
    >
      <p>{message}</p>
    </Dialog>
  );
}

ImportGroupModalConfirmation.propTypes = {
  visible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};

export default ImportGroupModalConfirmation;
