import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'; // Make sure to import moment library
import Highcharts from 'highcharts'; // Make sure to import Highcharts library
import HighchartsReact from 'highcharts-react-official'; // Make sure to import HighchartsReact wrapper
import HighchartsAccessibility from 'highcharts/modules/accessibility';

HighchartsAccessibility(Highcharts);

function SuccessTimelineComponent({ campaigns, title }) {
  const overviewData = campaigns.map((campaign) => {
    const campaignDate = moment.utc(campaign.created_date).local();
    campaign.y = 0;
    campaign.y += (campaign.stats.login_clicked ? campaign.stats.login_clicked : campaign.stats.learn_clicked);
    campaign.y = Math.floor((campaign.y / campaign.stats.total) * 100);
    return {
      campaign_id: campaign.id,
      name: campaign.name,
      x: campaignDate.valueOf(),
      y: campaign.y,
    };
  });

  const options = {
    chart: {
      zoomType: 'x',
      type: 'areaspline',
    },
    title: {
      text: title,
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        second: '%l:%M:%S',
        minute: '%l:%M',
        hour: '%l:%M',
        day: '%b %d, %Y',
        week: '%b %d, %Y',
        month: '%b %Y',
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: '% of Success',
      },
    },
    tooltip: {
      formatter() {
        return `${Highcharts.dateFormat('%A, %b %d %l:%M:%S %P', new Date(this.x))
        }<br>${this.point.name}<br>% Success: <b>${this.y}%</b>`;
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 3,
        },
        cursor: 'pointer',
        point: {
          events: {
            click(e) {
              window.location.href = `/campaign/${this.campaign_id}`;
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [{
      data: overviewData,
      color: '#f05b4f',
      fillOpacity: 0.5,
    }],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

SuccessTimelineComponent.propTypes = {
  campaigns: PropTypes.array,
  title: PropTypes.string,
};

SuccessTimelineComponent.defaultProps = {
  campaigns: [],
  title: '',
};

export default SuccessTimelineComponent;
