import { Tag } from 'primereact/tag';

export function formatDate(dateString) {
  if (dateString.startsWith('0001')) {
    return '-';
  }
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };
  return date.toLocaleDateString('en-ES', options);
}

export const RelationshipType = Object.freeze({
  ME: 'me',
  ANCESTOR: 'ancestor',
  DESCENDANT: 'descendant',
  EXTERNAL: 'external',
});

const ownerTypes = {
  me: {
    label: 'Me',
    color: 'info',
  },
  inherited: {
    label: 'Inherited',
    color: 'success',
  },
};

export const ownerInfo = (rowData) => {
  const owner = rowData.belongs_to === RelationshipType.ME ? RelationshipType.ME : 'inherited';
  return (
    <Tag
      value={ownerTypes[owner].label}
      severity={ownerTypes[owner].color}
    />
  );
};

export const landingPagesTypes = [
  {
    name: 'Learning', value: 'learning', color: '#ff9c9c', description: 'Teachable Moment', icon: 'pi pi-play',
  },
  {
    name: 'Credentials', value: 'credentials', color: '#1e3a8a', description: 'Compromise of Credentials', icon: 'pi pi-exclamation-circle',
  },
];
