/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { FileUpload } from 'primereact/fileupload';
import { CKEditor } from 'ckeditor4-react';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import yaml from 'js-yaml';
import domains from '@config/domains';
import ImportMailModalComponent from './ImportMailModalComponent/ImportMailModalComponent';
import { createTemplate, editTemplate } from '../../../apis/templates.ts';
import AttachmentsListComponent from '../AttachmentsListComponent/AttachmentsListComponent';
import { useToast } from '../../../context/ToastContext';

function checkTrackingImage(html) {
  return (html?.indexOf('{{.Tracker}}') !== -1);
}

const templateTypeOptions = [
  {
    name: 'Email',
  },
  {
    name: 'SMS',
  },
];

const calculateSMSCount = (text) => Math.ceil(text / 160);

const handleFileUpload = (event, setAttachments, toast, fileUploadRef) => {
  const { files } = event;

  files.forEach((file) => {
    const reader = new FileReader();

    reader.onload = () => {
      const newAttachment = {
        name: file.name,
        content: reader.result.split(',')[1],
        type: file.type || 'application/octet-stream',
      };
      setAttachments((prevAttachments) => {
        const attachments = prevAttachments || [];
        return [...attachments, newAttachment];
      });
    };

    reader.onerror = (e) => {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: e, life: 3000,
      });
    };

    reader.readAsDataURL(file);
    fileUploadRef.current.clear();
  });
};

const buildEnvelopeSender = (envelopeSender) => {
  const cleanEnvelopeSender = Object.fromEntries(
    Object.entries(envelopeSender).filter(([_, v]) => v !== undefined || v !== null),
  );
  if (Object.entries(cleanEnvelopeSender).every(([_, value]) => value === '' || !value)) {
    return null;
  }
  return `${cleanEnvelopeSender.name ? cleanEnvelopeSender.name : ''} <${cleanEnvelopeSender.email ? cleanEnvelopeSender.email : ''}@${cleanEnvelopeSender.subdomain ? `${cleanEnvelopeSender.subdomain}.` : ''}${cleanEnvelopeSender.domain ? cleanEnvelopeSender.domain : ''}>`;
};

const parseEmailString = (str) => {
  if (str) {
    const decodedString = str.replace(/\\u(\w{4})/g, (match, grp) => String.fromCharCode(parseInt(grp, 16)));
    const regex = /^(.*?)(?:\s<([^@]+)@(?:([^.]+)\.)?([^.]+)\.([^>]+)>)$/;
    const match = decodedString.match(regex);

    if (match) {
      const name = match[1].trim() || null;
      const email = match[2].trim();
      const subdomain = match[3] ? match[3].trim() : null;
      const domain = `${match[4].trim()}.${match[5].trim()}`;

      return {
        name, email, subdomain, domain,
      };
    }
  } else {
    return null;
  }
};

function ManageTemplatesFormComponent({
  template, mode, setVisible,
}) {
  const [attachments, setAttachments] = useState();
  const [visibleTestMail, setVisibleTestMail] = useState(false);
  const fileUploadRef = useRef(null);
  const [importEmail, setImportEmail] = useState();
  const [selectedTemplateType, setSelectedTemplateType] = useState();
  const [envelopeSender, setEnvelopeSender] = useState({
    name: null, email: null, subdomain: null, domain: null,
  });
  const [domainOptions, setDomainOptions] = useState([]);
  const [initHtmlData, setInitHtmlData] = useState(template?.html);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const schema = yup.object().shape({
    name: yup.string().min(2, 'Template name must be at least 2 characters').max(255, 'Template name must be at most 255 characters').required('Template name is required'),
    templateType: yup.object().shape({
      name: yup.string().test('name-exists', 'Name field is required', (value) => !!value).required('Name field is required'),
    }),
    envelope_sender: yup.object({
      name: yup.string().nullable(),
      email: yup.string().nullable()
        .when(['name', 'subdomain'], {
          is: (name, subdomain) => !!name || !!subdomain,
          then: () => yup.string().matches(/^[^\s]+$/, 'Email cannot contain spaces').required('Email is required'),
          otherwise: () => yup.string().nullable(),
        }),
      subdomain: yup.string().nullable(),
      domain: yup.string().nullable()
        .when(['name', 'email', 'subdomain'], {
          is: (name, email, subdomain) => !!name || !!email || !!subdomain,
          then: () => yup.string()
            .notOneOf(['', 'Select a domain'], 'Please select a valid domain')
            .required('Domain is required'),
          otherwise: () => yup.string().nullable(),
        }),
    }).test('email-and-domain-required', 'Email and Domain are required if any other field is provided', (value) => {
      const {
        name, email, subdomain, domain,
      } = value || {};
      if (!!name || !!subdomain || !!email || !!domain) {
        return !!email && !!domain;
      }
      return true;
    }),
    smsSenderId: yup.string().when('templateType.name', {
      is: 'SMS',
      then: () => yup.string()
        .max(11, 'SMS Sender ID must be at most 11 characters')
        .matches(/^[^\s]*$/, 'SMS Sender ID must not contain spaces'),
      otherwise: () => yup.string(),
    }),
    smstext: yup.string().when('templateType.name', {
      is: 'SMS',
      then: () => yup.string()
        .min(1, 'SMS Text is mandatory'),
      otherwise: () => yup.string(),
    }),
  });

  const defaultValues = {
    name: '',
    shared: false,
    templateType: '',
    envelope_sender: {
      name: null, email: null, subdomain: null, domain: null,
    },
    smsSenderId: '',
    subject: '',
    html: '',
    text: '',
    smstext: '',
    tracking_image: mode === 'create' ? false : checkTrackingImage(template?.html),
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    trigger,
    getValues,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    if (template) {
      setAttachments(template.attachments);
      setInitHtmlData(template.html);
      setSelectedTemplateType(template.template_type !== '' ? template.template_type : 'Email');
      const cleanEnvelopeSender = parseEmailString(template.envelope_sender);
      setEnvelopeSender(cleanEnvelopeSender || envelopeSender);
      reset({
        name: mode === 'edit' ? template.name : `Copy of ${template.name}`,
        shared: template.shared,
        envelope_sender: cleanEnvelopeSender || envelopeSender,
        templateType: template.template_type !== '' ? { name: template.template_type } : { name: 'Email' },
        smsSenderId: template.sms_sender_id,
        html: template.html,
        text: template.text,
        smstext: template.text,
        subject: template.subject,
        tracking_image: template.tracking_image,
      });
    }
  }, [template]);

  useEffect(() => {
    if (importEmail) {
      setAttachments(importEmail.attachments);
      setInitHtmlData(importEmail.html);
      setValue('html', importEmail.html);
      setValue('text', importEmail.text);
      setValue('subject', importEmail.subject);
    }
  }, [importEmail]);

  useEffect(() => {
    const fetchYamlData = async () => {
      try {
        fetch(domains)
          .then((r) => r.text())
          .then((text) => {
            const domainsList = yaml.loadAll(text).flat().filter((item) => item != null)
              .map((item) => ({
                name: item.id,
                value: item.id,
              }));
            setDomainOptions(domainsList);
          });
      } catch (error) {
        console.error('Error loading or parsing YAML file:', error);
      }
    };
    fetchYamlData();
  }, []);

  async function onSubmit() {
    setIsSubmitting(true);
    const data = { ...getValues(), attachments };
    const sanitizedSendData = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v !== undefined),
    );
    let response = {};
    let body = {};
    if (selectedTemplateType === 'SMS') {
      const {
        name, shared, smsSenderId, smstext,
      } = sanitizedSendData;
      body = {
        name,
        template_type: selectedTemplateType,
        shared,
        sms_sender_id: smsSenderId,
        text: smstext,
      };
    }
    if (selectedTemplateType === 'Email') {
      const {
        // eslint-disable-next-line camelcase
        smsSenderId, templateType, smstext, envelope_sender, ...payload
      } = sanitizedSendData;
      body = {
        template_type: selectedTemplateType,
        envelope_sender: buildEnvelopeSender(envelopeSender),
        ...payload,
      };
    }
    if (mode === 'edit') {
      response = await editTemplate({ ...body, id: template.id });
    } else {
      response = await createTemplate({ ...body });
    }
    if (response.response?.data.success === false) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.response.data.message, life: 3000,
      });
    } else {
      setVisible(false);
      toast.current.show({
        severity: 'success', summary: 'Success', detail: mode === 'edit' ? 'Template edited' : 'Template created', life: 3000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setIsSubmitting(false);
  }
  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  return (
    <>
      <ImportMailModalComponent
        visible={visibleTestMail}
        setVisible={setVisibleTestMail}
        setImportEmail={setImportEmail}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2 text-left ml-3">
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <div className="mb-2">
                <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                  Name*
                </label>
                <InputText
                  id={field.name}
                  placeholder="Template name"
                  value={field.value}
                  className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                  onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                  aria-describedby={`${field.name}-info`}
                />
                <div className="mb-1">
                  {getFormErrorMessage(field.name)}
                </div>
              </div>
            )}
          />
          <div className="mb-5">
            <Controller
              name="shared"
              control={control}
              render={({ field }) => (
                <label htmlFor={field.name} className="block">
                  <Checkbox
                    id={field.name}
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    className="mr-2"
                    onChange={(e) => {
                      field.onChange(e.checked);
                    }}
                  />
                  Share template
                  <Tooltip target=".custom-target-icon" className="w-3" />
                  <i
                    className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                    data-pr-tooltip="If you share the template, it will be accessible for use by other users associated with your account."
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ cursor: 'pointer' }}
                  />
                </label>
              )}
            />
          </div>
          <Controller
            name="templateType"
            control={control}
            render={({ field }) => (
              <div className="mb-2">
                <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                  Template type*
                </label>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => {
                    setSelectedTemplateType(e.value.name);
                    setValue(field.name, { name: e.value.name });
                    trigger(field.name);
                  }}
                  options={templateTypeOptions}
                  optionLabel="name"
                  placeholder="Select an template type"
                  className="w-full md:w-30rem"
                  aria-describedby={`${field.name}-info`}
                  required
                />
                <div className="mb-1">
                  {getFormErrorMessage(field.name)}
                </div>
              </div>
            )}
          />
          {selectedTemplateType === 'SMS' && (
            <>
              <Controller
                name="smsSenderId"
                control={control}
                render={({ field }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      SMS Sender ID*
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="This sender is shown to the user as the SMS sender. No spaces are allowed. Maximum length allowed is 11 characters."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center-2"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <InputText
                      id={field.name}
                      placeholder="Company"
                      value={field.value}
                      className="w-full md:w-30rem"
                      onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="smstext"
                control={control}
                render={({ field }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      SMS Text*
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="Use {{.URL}} on the SMS text to add the target URL for tracking user actions. This will automatically be replaced by the URL indicated when launching the campaign"
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center-2"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <InputTextarea
                      id={field.name}
                      placeholder="Plain text"
                      autoResize
                      rows={10}
                      value={field.value}
                      className="w-full border-noround"
                      onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                    <div style={{ textAlign: 'right', marginTop: '5px' }}>
                      <div>
                        {getValues().smstext.length}
                        {' '}
                        characters
                      </div>
                      <div>
                        1 SMS holds 160 characters, you are currently sending
                        {' '}
                        {calculateSMSCount(getValues().smstext.length)}
                        {' '}
                        + the phishing URL
                      </div>
                    </div>
                  </div>
                )}
              />
            </>
          )}
          {selectedTemplateType === 'Email' && (
            <>
              <Controller
                name="envelope_sender"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Envelope Sender
                      <Tooltip target=".custom-target-icon" className="w-3" />
                      <i
                        className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                        data-pr-tooltip="This sender is shown to the user by most email clients. Defaults to the SMTP From as defined in the Sending Profile."
                        data-pr-position="right"
                        data-pr-at="right+5 top"
                        data-pr-my="left center-2"
                        style={{ cursor: 'pointer' }}
                      />
                    </label>
                    <div className="flex items-center space-x-4">
                      <InputText
                        id={`${field.name}-name`}
                        placeholder="Name"
                        value={field.value?.name}
                        className="w-full md:w-30rem"
                        onChange={(e) => {
                          field.onChange({ ...envelopeSender, name: e.target.value });
                          setEnvelopeSender({ ...envelopeSender, name: e.target.value });
                          trigger(field.name);
                        }}
                        aria-describedby={`${field.name}-name`}
                      />
                      <InputText
                        id={`${field.name}-email`}
                        placeholder="test"
                        value={field.value?.email}
                        className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                        onChange={(e) => {
                          field.onChange({ ...envelopeSender, email: e.target.value });
                          setEnvelopeSender({ ...envelopeSender, email: e.target.value });
                          trigger(field.name);
                        }}
                        aria-describedby={`${field.name}-email`}
                      />
                      <InputText
                        id={`${field.name}-subdomain`}
                        placeholder="subdomain"
                        value={field.value?.subdomain}
                        className="w-full md:w-30rem"
                        onChange={(e) => {
                          field.onChange({ ...envelopeSender, subdomain: e.target.value });
                          setEnvelopeSender({ ...envelopeSender, subdomain: e.target.value });
                          trigger(field.name);
                        }}
                        aria-describedby={`${field.name}-subdomain`}
                      />
                      <Dropdown
                        id={`${field.name}-domain`}
                        value={field.value?.domain}
                        onChange={(e) => {
                          const updatedValue = { ...field.value, domain: e.value };
                          setEnvelopeSender(updatedValue);
                          setValue(field.name, updatedValue);
                          trigger(field.name);
                        }}
                        options={domainOptions}
                        optionLabel="name"
                        optionValue="name"
                        placeholder="Select a domain"
                        className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                        aria-describedby={`${field.name}-domain`}
                        filter
                        showClear
                        required
                      />
                    </div>
                    <label className="mt-2">
                      <b>
                        Resulting Envelope Sender:&nbsp;
                      </b>
                      {envelopeSender && buildEnvelopeSender(envelopeSender)}
                    </label>
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <div>
                <Button type="button" icon="pi pi-envelope" className="mb-4 gap-2" onClick={() => setVisibleTestMail(true)}>Import Email</Button>
              </div>
              <Controller
                name="subject"
                control={control}
                render={({ field }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Subject
                    </label>
                    <InputText
                      id={field.name}
                      placeholder="Email Subject"
                      value={field.value}
                      className="w-full"
                      onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <TabView className="mb-2">
                <TabPanel header="Text">
                  <Controller
                    name="text"
                    control={control}
                    render={({ field }) => (
                      <div>
                        <InputTextarea
                          id={field.name}
                          placeholder="Plain text"
                          autoResize
                          rows={10}
                          value={field.value}
                          className="w-full border-noround"
                          onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                          aria-describedby={`${field.name}-info`}
                        />
                      </div>
                    )}
                  />
                </TabPanel>
                <TabPanel header="HTML">
                  <Controller
                    name="html"
                    control={control}
                    render={({ field }) => (
                      <div>
                        <CKEditor
                          key={initHtmlData}
                          initData={field.value}
                          data={field.value}
                          editorUrl="./ckeditor/ckeditor.js"
                          config={{
                            startupMode: 'wysiwyg',
                          }}
                          onChange={(data) => field.onChange(data.editor.getData())}
                          onBlur={(data) => field.onChange(data.editor.getData())}
                          // Hack for solve Source mode lack of sync
                          onKey={(evt) => {
                            setTimeout(() => {
                              field.onChange(evt.editor.getData());
                            }, 0);
                          }}
                        />
                      </div>
                    )}
                  />
                </TabPanel>
              </TabView>
              <Controller
                name="tracking_image"
                control={control}
                render={({ field }) => (
                  <label htmlFor={field.name}>
                    <Checkbox
                      id={field.name}
                      inputId={field.name}
                      checked={field.value}
                      inputRef={field.ref}
                      className="mr-2"
                      onChange={(e) => field.onChange(e.checked)}
                    />
                    Add Tracking Image
                  </label>
                )}
              />
              <div className="mb-4">
                <FileUpload ref={fileUploadRef} mode="basic" icon="pi pi-plus" className="gap-2 mt-4" accept="*/*" maxFileSize={1000000} customUpload uploadHandler={(event) => handleFileUpload(event, setAttachments, toast, fileUploadRef)} auto chooseLabel="Add Files" />
              </div>
              <AttachmentsListComponent attachments={attachments} setAttachments={setAttachments} />
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1rem' }}>
            <Button
              label={isSubmitting ? 'Saving...' : 'Save'}
              icon={isSubmitting ? 'pi pi-spin pi-spinner' : 'pi pi-save'}
              disabled={!isValid || isSubmitting}
              type="button"
              onClick={() => onSubmit()}
              autoFocus
            />
          </div>
        </div>
      </form>
    </>
  );
}

ManageTemplatesFormComponent.propTypes = {
  template: PropTypes.object,
  mode: PropTypes.string,
  setVisible: PropTypes.func.isRequired,
};

ManageTemplatesFormComponent.defaultProps = {
  template: {},
  mode: null,
};

export default ManageTemplatesFormComponent;
