import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { TieredMenu } from 'primereact/tieredmenu';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../utils';
import ManageUserManagementModalComponent from '../ManageUserManagementModalComponent/ManageUserManagementModalComponent';
import { impersonateUserManagement, deleteUserManagement } from '../../../apis/usersManagement.ts';
import { useToast } from '../../../context/ToastContext';
import { setUserInfoState } from '../../../redux/reducers/actions/userInfoActions';

async function acceptImpersonateUserConfirmation(username, toast, dispatch) {
  const response = await impersonateUserManagement({ username, csrf_token: '1234' });
  if (response.success) {
    toast.current.show({
      severity: 'success', summary: 'Success', detail: response.message, life: 3000,
    });
    dispatch(setUserInfoState(
      {
        name: response.data.username,
        role: response.data.role,
        apiKey: response.apiKey,
        id: response.data.id,
      },
    ));
    if (response.data.reset_pwd) {
      window.location.href = '/reset';
    } else {
      window.location.href = '/';
    }
  } else {
    toast.current.show({
      severity: 'error', summary: 'Error', detail: `There was a problem trying to impersonate ${username}: ${response.message}`, life: 3000,
    });
  }
}

const impersonateUserConfirmation = (userManagement, toast, dispatch) => {
  confirmDialog({
    message: `You will be logged out of your account and logged in as ${userManagement.username}`,
    header: `Impersonate ${userManagement.username}`,
    icon: 'pi pi-info-circle',
    defaultFocus: 'reject',
    acceptClassName: 'p-button-primary',
    rejectClassName: 'p-button-secondary',
    acceptLabel: 'Swap user',
    accept: () => {
      acceptImpersonateUserConfirmation(userManagement.username, toast, dispatch);
    }
    ,
  });
};

async function acceptDeleteUserConfirmation(userManagement, toast) {
  const response = await deleteUserManagement(userManagement.id);
  if (response.success) {
    toast.current.show({
      severity: 'success', summary: 'Success', detail: response.message, life: 3000,
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } else {
    toast.current.show({
      severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? `There was a problem trying to remove the user: ${response.response.data.message}` : 'An unexpected error occurred deleting the user', life: 3000,
    });
  }
}

const deleteUserManagementConfirmation = (userManagement, toast) => {
  confirmDialog({
    message: 'Do you want to delete this user?',
    header: 'Delete User',
    icon: 'pi pi-info-circle',
    defaultFocus: 'reject',
    acceptClassName: 'p-button-danger',
    accept: () => acceptDeleteUserConfirmation(userManagement, toast),
  });
};

function UserManagementListComponent({ usersManagement }) {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [userManagementId, setUserManagementId] = useState(null);
  const [selectedUsersManagement, setSelectedUsersManagement] = useState([]);
  const settingsRef = useRef(null);
  const toast = useToast();
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    tenant_id: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      ],
    },
    username: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      ],
    },
    'role.name': {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.EQUALS },
      ],
    },
    last_login: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.DATE_IS },
      ],
    },
  });

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };

    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => (
    <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
      <h4 className="m-0">Users</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
      </span>
    </div>
  );
  const header = renderHeader();
  const dateBodyTemplate = (rowData) => formatDate(rowData.last_login);
  const dateFilterTemplate = (options) => <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
  const actions = () => (
    [
      {
        label: 'Impersonate',
        icon: 'pi pi-user',
        command: () => {
          impersonateUserConfirmation(selectedUsersManagement, toast, dispatch);
        },
      },
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        command: () => { setVisible(true); },
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => { deleteUserManagementConfirmation(selectedUsersManagement, toast); },
      },
    ]);

  const actionBodyTemplate = (value) => (
    <Button type="button" icon="pi pi-cog" className="justify-content-center align-items-center h-3rem w-3rem border-circle" onClick={(e) => { settingsRef.current.toggle(e); setSelectedUsersManagement(value); setUserManagementId(value.id); }}>
      <TieredMenu model={actions()} popup ref={settingsRef} style={{ width: 'auto' }} />
    </Button>
  );

  const getParent = (value) => usersManagement?.find((u) => u.id === value?.parent_id)?.username || '-';

  const getTenant = (value) => value.tenant_id || '-';

  return (
    usersManagement?.length > 0 && (
      <>
        <ConfirmDialog />
        <ManageUserManagementModalComponent
          visible={visible}
          setVisible={setVisible}
          userManagementId={userManagementId}
          users={usersManagement}
        />
        <DataTable
          size="small"
          value={usersManagement}
          paginator
          header={header}
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50]}
          dataKey="id"
          selection={selectedUsersManagement}
          onSelectionChange={(e) => setSelectedUsersManagement(e.value)}
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={['username', 'role.name', 'last_login']}
          emptyMessage="No users found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
        >
          <Column field="username" header="Username" sortable filter filterPlaceholder="Search by name" style={{ minWidth: '4rem' }} />
          <Column field="tenant_id" header="Tenant" sortable filter filterPlaceholder="Search by tenant name" style={{ minWidth: '4rem' }} body={(e) => getTenant(e)} />
          <Column field="parent" header="Parent" sortable style={{ minWidth: '4rem' }} body={(e) => getParent(e)} />
          <Column field="role.name" header="Role" sortable filter filterPlaceholder="Search by role" style={{ minWidth: '2rem' }} />
          <Column field="last_login" header="Last login" sortable dataType="date" style={{ minWidth: '12rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
          <Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={(e) => actionBodyTemplate(e)} />
        </DataTable>
      </>
    )
  );
}

UserManagementListComponent.propTypes = {
  usersManagement: PropTypes.array,
};

UserManagementListComponent.defaultProps = {
  usersManagement: [],
};

export default UserManagementListComponent;
