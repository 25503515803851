import { confirmDialog } from 'primereact/confirmdialog';
import { deleteCampaign } from '../../apis/campaigns.ts';

export const statusesInfo = {
  Error: {
    label: 'danger',
  },
  'In progress': {
    label: 'info',
  },
  Queued: {
    label: 'warning',
  },
  Completed: {
    label: 'success',
  },
  'Email Sent': {
    color: '#1abc9c',
    label: 'label-success',
    icon: 'pi pi-envelope',
    point: 'ct-point-sent',
  },
  'SMS Sent': {
    color: '#1abc9c',
    label: 'label-success',
    icon: 'pi pi-mobile',
    point: 'ct-point-sent',
  },
  'Emails Sent': {
    color: '#1abc9c',
    label: 'label-success',
    icon: 'pi pi-envelope',
    point: 'ct-point-sent',
  },
  'Email Opened': {
    color: '#f9bf3b',
    label: 'label-warning',
    icon: 'pi pi-eye',
    point: 'ct-point-opened',
  },
  'Login Clicked': {
    color: '#f39c12',
    label: 'label-clicked',
    icon: 'pi pi-external-link',
    point: 'ct-point-clicked',
  },
  'SMS Login Clicked': {
    color: '#f39c12',
    label: 'label-clicked',
    icon: 'pi pi-external-link',
    point: 'ct-point-clicked',
  },
  Success: {
    color: '#f05b4f',
    label: 'label-danger',
    icon: 'pi pi-exclamation-triangle',
    point: 'ct-point-clicked',
  },
  // not a status, but is used for the campaign timeline and user timeline
  'Email Reported': {
    color: '#45d6ef',
    label: 'label-info',
    icon: 'pi pi-megaphone',
    point: 'ct-point-reported',
  },
  Error: {
    color: '#6c7a89',
    label: 'label-default',
    icon: 'pi pi-times',
    point: 'ct-point-error',
  },
  'Error Sending Email': {
    color: '#6c7a89',
    label: 'label-default',
    icon: 'pi pi-times',
    point: 'ct-point-error',
  },
  'Error Sending SMS': {
    color: '#6c7a89',
    label: 'label-default',
    icon: 'pi pi-times',
    point: 'ct-point-error',
  },
  'Submitted Data': {
    color: '#f05b4f',
    label: 'label-danger',
    icon: 'pi pi-exclamation-circle',
    point: 'ct-point-clicked',
  },
  'SMS Submitted Data': {
    color: '#f05b4f',
    label: 'label-danger',
    icon: 'pi pi-exclamation-circle',
    point: 'ct-point-clicked',
  },
  'Learn Clicked': {
    color: '#ff9c9c',
    label: 'label-danger',
    icon: 'pi pi-book',
    point: 'ct-point-clicked',
  },
  'SMS Learn Clicked': {
    color: '#ff9c9c',
    label: 'label-danger',
    icon: 'pi pi-book',
    point: 'ct-point-clicked',
  },
  Unknown: {
    color: '#6c7a89',
    label: 'label-default',
    icon: 'pi pi-question',
    point: 'ct-point-error',
  },
  Sending: {
    color: '#428bca',
    label: 'label-primary',
    icon: 'pi pi-send',
    point: 'ct-point-sending',
  },
  Retrying: {
    color: '#6c7a89',
    label: 'label-default',
    icon: 'pi pi-replay',
    point: 'ct-point-error',
  },
  Scheduled: {
    color: '#428bca',
    label: 'label-primary',
    icon: 'pi pi-clock',
    point: 'ct-point-sending',
  },
  'Campaign Created': {
    color: '#9abc9c',
    label: 'label-success',
    icon: 'pi pi-check',
  },
};

export const templateTypes = {
  SMS: {
    label: 'SMS',
    color: 'info',
    icon: 'pi pi-mobile',
  },
  Email: {
    label: 'Email',
    color: 'warning',
    icon: 'pi pi-envelope',
  },
};

export function countOccurrences(key, data) {
  let count = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const item of data) {
    if (item.message === key) {
      // eslint-disable-next-line no-plusplus
      count++;
    }
  }

  return count;
}

export const statsMapping = {
  error: {
    name: 'Error Sending Email',
    color: '--gray-500',
    hover: '--gray-400',
  },
  sent: {
    name: 'Email Sent',
    color: '--green-500',
    hover: '--green-400',
  },
  opened: {
    name: 'Email Opened',
    color: '--yellow-500',
    hover: '--yellow-400',
  },
  email_reported: {
    name: 'Email Reported',
    color: '--blue-500',
    hover: '--blue-400',
  },
  login_clicked: {
    name: 'Login Clicked',
    color: '--orange-500',
    hover: '--orange-400',
  },
  submitted_data: {
    name: 'Submitted Data',
    color: '--red-500',
    hover: '--red-400',
  },
  learn_clicked: {
    name: 'Learn Clicked',
    color: '--red-200',
    hover: '--red-100',
  },
};

export const statsSMSMapping = {
  error: {
    name: 'Error Sending SMS',
    color: '--gray-500',
    hover: '--gray-400',
  },
  sent: {
    name: 'SMS Sent',
    color: '--green-500',
    hover: '--green-400',
  },
  login_clicked: {
    name: 'SMS Login Clicked',
    color: '--orange-500',
    hover: '--orange-400',
  },
  submitted_data: {
    name: 'SMS Submitted Data',
    color: '--red-500',
    hover: '--red-400',
  },
  learn_clicked: {
    name: 'SMS Learn Clicked',
    color: '--red-200',
    hover: '--red-100',
  },
};

async function acceptDeleteCampaign(campaignId, toast, navigate) {
  const response = await deleteCampaign(campaignId);
  if (response.success) {
    // TODO: store campaigns in Redux and remove it
    toast.current.show({
      severity: 'success', summary: 'Success', detail: response.message, life: 3000,
    });
    if (window.location.pathname === '/campaigns' || window.location.pathname === '/') {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      navigate('/campaigns');
    }
  } else {
    toast.current.show({
      severity: 'error', summary: 'Error', detail: response?.response?.data?.message ? `There was a problem trying to remove the campaign: ${response.response.data.message}` : 'An unexpected error occurred deleting the campaign', life: 3000,
    });
  }
}

export const deleteCampaignConfirmation = (campaignId, toast, navigate) => {
  confirmDialog({
    message: 'Do you want to delete this campaign?',
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    defaultFocus: 'reject',
    acceptClassName: 'p-button-danger',
    accept: () => acceptDeleteCampaign(campaignId, toast, navigate),
  });
};

export const getScreenWidth = () => window.innerWidth;

export const difficultyOptions = [
  { name: 'Very easy', value: 1, color: '#4CAF50' },
  { name: 'Easy', value: 2, color: '#8BC34A' },
  { name: 'Medium', value: 3, color: '#FFC107' },
  { name: 'Hard', value: 4, color: '#FF9800' },
  { name: 'Very hard', value: 5, color: '#F44336' },
];
