import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { getLandingPage } from '../../../../apis/landingPages.ts';
import { getTemplate } from '../../../../apis/templates.ts';
import './InformationComponent.scss';
import CustomSpinnerComponent from '../../../CustomSpinnerComponent/CustomSpinnerComponent';
import CampaignResumeComponent from '../../ManageCampaignFormComponent/CampaignResumeComponent/CampaignResumeComponent';
import { getSmtpGateway, getSmsGateway } from '../../../../apis/sendingProfiles.ts';
import { getCampaignGroups } from '../../../../apis/usersAndGroups.ts';

function InformationComponent({ campaign, openInfoModal, setOpenInfoModal }) {
  const [credentialsPage, setCredentialsPage] = useState();
  const [learningPage, setLearningPage] = useState();
  const [template, setTemplate] = useState();
  const [sendingProfile, setSendingProfile] = useState();
  const [groups, setGroups] = useState();

  useEffect(() => {
    async function fetchData() {
      if (campaign.learning_page) {
        const response = await getLandingPage(campaign.learning_page);
        if (response.response?.data.success === false) {
          setLearningPage(null);
        } else {
          setLearningPage(response);
        }
      } else {
        setLearningPage(null);
      }
      if (campaign.credentials_page === 0) {
        // Inform when optional for handling messages on CampaignResumeComponent
        setCredentialsPage(0);
      } else if (campaign.credentials_page) {
        const response = await getLandingPage(campaign.credentials_page);
        if (response.response?.data.success === false) {
          setCredentialsPage(null);
        } else {
          setCredentialsPage(response);
        }
      } else {
        setCredentialsPage(null);
      }
      if (campaign.template) {
        const response = await getTemplate(campaign.template);
        if (response.response?.data.success === false) {
          setTemplate(null);
        } else {
          setTemplate(response);
        }
      } else {
        setTemplate(null);
      }
      if (campaign.sms_gateway) {
        const response = await getSmsGateway(campaign.sms_gateway);
        if (response.response?.data.success === false) {
          setSendingProfile(null);
        } else {
          setSendingProfile(response);
        }
      }
      if (campaign.smtp) {
        const response = await getSmtpGateway(campaign.smtp);
        if (response.response?.data.success === false) {
          setSendingProfile(null);
        } else {
          setSendingProfile(response);
        }
      }
      if (campaign.id) {
        const response = await getCampaignGroups(campaign.id);
        if (response.response?.data.success === false) {
          setGroups([]);
        } else {
          setGroups(response);
        }
      } else {
        setGroups([]);
      }
    }
    fetchData();
  }, [campaign]);

  const campaignInfo = {
    ...campaign, credentialsPage, learningPage, template, sendingProfile, groups,
  };

  return (
    <Dialog header="Information" modal draggable={false} visible={openInfoModal} style={{ width: '50vw' }} onHide={() => setOpenInfoModal(false)}>
      {
        learningPage !== undefined
        && credentialsPage !== undefined
        && template !== undefined
        && sendingProfile !== undefined
        && campaign
        && groups !== undefined
          ? <CampaignResumeComponent campaignInfo={campaignInfo} />
          : <CustomSpinnerComponent text="campaign" />
      }
    </Dialog>
  );
}

InformationComponent.propTypes = {
  campaign: PropTypes.object,
  openInfoModal: PropTypes.bool.isRequired,
  setOpenInfoModal: PropTypes.func.isRequired,
};

InformationComponent.defaultProps = {
  campaign: {},
};

export default InformationComponent;
